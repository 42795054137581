$block: '.button';

#{$block} {
  width: max-content;
  padding: 5px;

  button {
    padding: 10px 20px;
    background-color: $blue;
    border-radius: 50px;
    border: none;
    color: #fff;
  }
}