$block: '.apiReference';

#{$block} {
  ul {
    padding: 0;
    margin: 0;
  }

  li {
    list-style: none;
  }

  &__wrapper {
    display: flex;
    color: $grayDarker;
    height: 100vh;
    font-family: Circular Std;
  }

  &__sidebar {
    height: 100%;

    &__wrapper {
      min-width: 280px;
      height: 100%;
    }

    &-logo {
      margin: 30px;
    }

    &-list {
      height: 100%;
      display: block;
      overflow-y: auto;
    }

    &-item {
      padding: 15px;
      font-size: 16px;
      line-height: 19px;
      cursor: pointer;

      &:hover {
        background-color: $grayExtraLight;
      }

      &.active {
        background-color: $grayLighter;
      }
    }
  }

  &__table-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;

    div {
      padding: 5px;
      width: 100%;
    }
  }

  &__data {
    padding: 48px 16px;
    max-width: 540px;
    width: 100%;
    border-left: 1px solid $grayLight;
    overflow: scroll;

    &-container {
      display: flex;
      width: 100%;
      height: 100%;
    }

    &-title {
      font-size: 22px;
      line-height: 27px;
      margin-bottom: 8px;
    }

    &-description {
      line-height: 28px;
      margin-bottom: 27px;
    }
  }

  &__method {
    margin-bottom: 10px;
  }

  &__url {
    margin-bottom: 50px;
    padding: 3px 15px;
    line-height: 26px;
    background-color: $grayLighter;
    word-break: break-all;
  }

  &__parameters {
    margin-bottom: 80px;

    &-title {
      margin-bottom: 15px;
      font-size: 19px;
      font-weight: normal;
    }
  }

  &__parameter {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    &-item {
      padding: 5px;
      width: 100%;
    }

    &-name {
      word-break: break-all;

      span {
        line-height: 22px;
        background: $grayLighter;
        padding: 2px 15px;
      }
    }

    &-description {
      font-weight: 100;

      span {
        font-weight: bold;
      }

      div {
        span {
          font-size: 12px;
          color: $gray;
        }
      }

    }

    &-type {
      white-space: pre-line;
      width: max-content;
      background-color: $grayLighter;
      padding: 0 5px;
    }
  }

  &__attribute {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    &-item {
      padding: 5px;
      width: 100%;
    }

    &-name {
      word-break: break-all;

      span {
        line-height: 22px;
        background: $grayLighter;
        padding: 2px 15px;
      }
    }

    &-description {
      font-weight: 100;
    }
  }

  &__response {
    width: 100%;
    background: #25303D;
    font-weight: 100;
    padding: 15px;
    overflow: scroll;

    &-container {
      position: relative;
      top: 25px;

      span {
        font-size: 16px;
      }

      pre {
        padding: 10px;
        background: #18212E;
        max-width: 540px;

        span {
          font-size: 14px;
        }
      }

      .key {
        color: $blueLight;
      }

      .string {
        color: $green;
      }

      .number {
        color: $red;
      }

      .null {
        color: $orangeLight;
      }

      .boolean {
        color: $blueDark;
      }
    }
  }

  &__attributes {
    margin-bottom: 60px;
  }

  &__request-example {
    margin-bottom: 30px;
    padding: 10px;
    background-color: $grayLighter;

    span {
      font-size: 18px;
      line-height: 20px;
      margin-bottom: 8px;
    }
  }
}
